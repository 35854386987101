import { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
}

const options = [
  { label: 'Selecione...', value: '' },
  { label: 'MÍNIMO', value: 'Mínimo' },
  { label: 'MÉDIA', value: 'Média' },
  { label: 'MÁXIMO', value: 'Máximo' },
]

const CalculationType: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState(row.calculation_type)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics/update'
      const req = {
        id: row.id,
        calculation_type: newVal,
      }

      api.post(url, req).catch(() => {
        setValue(row.calculation_type)
      })
    },
    [row]
  )

  return (
    <Select w="8rem" value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default CalculationType
