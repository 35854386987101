import { useMemo } from 'react'

import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'

import Input from './Input'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  loading: boolean
  binaryPos: number[]
}

interface ICellProps {
  row: IRow
  clusterId: string
  updateRow: (newRow: { [key: string]: any }) => void
}

const Table: React.FC<IProps> = ({
  rows,
  loading,
  page,
  limit,
  total,
  totalPages,
  setPage,
}) => {
  const cols = useMemo(() => {
    return [
      {
        Header: 'ICMS origem',
        accessor: 'icms',
      },
      {
        Header: 'ICMS Destino',
        accessor: 'icms_destino',
      },
      {
        Header: 'MVA',
        accessor: 'mva',
      },

      {
        Header: 'Fator',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input row={row} param="fator" updateRow={updateRow} />
        ),
      },
    ]
  }, [])

  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <TableBackend
        rows={rows}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
      />
    </Paper>
  )
}

export default Table
