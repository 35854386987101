import { useCallback, useState, useEffect } from 'react'
import { Divider, VStack } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'

import Name from './Name'
import Footer from './Footer'
import Filters from './Filters'
import PriceType from './PriceType'
import VolumePeriod from './VolumePeriod'

const Panel: React.FC = () => {
  const [name, setName] = useState('')
  const [type, setType] = useState('TABELA')
  const [loading, setLoading] = useState(true)
  const [dateIni, setDateIni] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: IOption[] }>({})
  const [options, setOptions] = useState<{ [key: string]: IOption[] }>({})

  const getFilters = useCallback(() => {
    if (!loading) return
    api.get('discount-scenery/new/filters').then((res) => {
      const newFilters: { [key: string]: IOption[] } = {}
      const newOptions: { [key: string]: IOption[] } = {}

      Object.keys(res.data.data.filters).forEach((key) => {
        newFilters[key] = []
        newOptions[key] = res.data.data.filters[key]
          .filter((item: any) => item)
          .map((item: any) => {
            if (typeof item === 'string') {
              return { label: item, value: item }
            }

            return { label: item.name, value: item.id }
          })
      })

      setFilters(newFilters)
      setOptions(newOptions)
      setLoading(false)
    })
  }, [loading, setLoading])
  useEffect(getFilters, [getFilters])

  if (loading) return <LoadingScreen />
  return (
    <Paper
      containerStyle={{
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto',
        overflow: 'visible',
      }}
    >
      <VStack w="100%" align="flex-start" spacing="1rem">
        <PriceType type={type} setType={setType} />
        <Name name={name} setName={setName} />
        <VolumePeriod
          dateIni={dateIni}
          dateEnd={dateEnd}
          setDateIni={setDateIni}
          setDateEnd={setDateEnd}
        />
        <Filters
          type={type}
          options={options}
          filters={filters}
          setFilters={setFilters}
        />
        <Divider />
        <Footer
          name={name}
          type={type}
          filters={filters}
          dateIni={dateIni}
          dateEnd={dateEnd}
        />
      </VStack>
    </Paper>
  )
}

export default Panel
