import { useMemo } from 'react'

import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'

import Input from './Input'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  levels: number
  loading: boolean
  clusterId: string
  binaryPos: number[]
}

interface ICellProps {
  row: IRow
  clusterId: string
  updateRow: (newRow: { [key: string]: any }) => void
}

const Table: React.FC<IProps> = ({
  rows,
  levels,
  loading,
  clusterId,
  binaryPos,
  page,
  limit,
  total,
  totalPages,
  setPage,
}) => {
  const cols = useMemo(() => {
    const startCols = [
      {
        Header: 'Canal Pricing',
        accessor: 'channel_name',
      },
    ]

    const nivelCols = [
      {
        Header: 'Nível 1',
        accessor: 'nivel01',
      },
      {
        Header: 'Nível 2',
        accessor: 'nivel02',
      },
      {
        Header: 'Nível 3',
        accessor: 'nivel03',
      },
      {
        Header: 'Nível 4',
        accessor: 'nivel04',
      },
      {
        Header: 'Nível 5',
        accessor: 'nivel05',
      },
    ].filter((_, i) => levels >= binaryPos[i])

    const baseCols = [
      {
        Header: 'Desconto',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input
            row={row}
            param="discount"
            clusterId={clusterId}
            updateRow={updateRow}
          />
        ),
      },
    ]

    return [...startCols, ...nivelCols, ...baseCols]
  }, [binaryPos, levels])

  if (!clusterId) return null
  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <TableBackend
        rows={rows}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
        cellProps={{ clusterId }}
      />
    </Paper>
  )
}

export default Table
