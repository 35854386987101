export const parameters = [
  'Cluster de Preço',
  'Relatividade de Produto',
  'Relatividade de Canais',
  'Classificação do Item',
  'Margem Indústria',
  'Margem Mercado',
  // 'Arredondamento',
  'Estratégia de Posicionamento',
  'Regras de Desconto',
  'Upload de Preços',
  'Fator ICMS',
]
