import { useCallback, useEffect, useMemo, useState } from 'react'
import { HStack, useToast } from '@chakra-ui/react'

import api from 'services/api'
import Options from 'pages/ParametersEdit/Table/_layouts/Options'

import IRow from './types/IRow'
import Table from './Table'

const limit = 15
const binaryPos = [1, 2, 4, 8, 16]
const parameter = 'margem_mercado'

const MargemMercado = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [level, setLevel] = useState('')
  const [levels, setLevels] = useState(1)
  const [loading, setLoading] = useState(true)
  const [request, setRequest] = useState({})
  const [clusterId, setClusterId] = useState('')

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const levelsToBinary = useMemo(() => {
    const binary = levels.toString(2).replace(/0/g, '1').split('')

    while (binary.length < 5) {
      binary.unshift('0')
    }

    return binary.reverse().join('')
  }, [levels])

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [clusterId, request, levels])

  const getLevel = useCallback(() => {
    if (!clusterId) return

    const url = `/config/default-parameter-levels?parameter=${parameter}&cluster_id=${clusterId}`

    api.get(url).then((res) => {
      let index = binaryPos.length

      const zeroPos = res.data.data.config.levels.indexOf('0')

      if (zeroPos >= 0) {
        index = zeroPos
      }

      setLevel(res.data.data.config.level)
      setLevels(binaryPos[index - 1])
    })
  }, [clusterId])
  useEffect(getLevel, [getLevel])

  const getRows = useCallback(() => {
    if (!loading || !clusterId || !level) return

    const url = `/market-margins?id=${clusterId}&limit=${limit}&page=${page}&levels=${levelsToBinary}`

    api
      .post(url, request)
      .then((response) => {
        setRows(response.data.data.market_margins)
        setTotal(response.data.data.total)
        setTotalPage(response.data.data.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [clusterId, level, levelsToBinary, loading, page, request, toast])
  useEffect(getRows, [getRows])

  return (
    <HStack align="flex-start" spacing={6}>
      <Options
        level={level}
        parameter={parameter}
        clusterId={clusterId}
        setRequest={setRequest}
        setClusterId={setClusterId}
      />
      <Table
        rows={rows}
        levels={levels}
        loading={loading}
        clusterId={clusterId}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={total}
        setPage={(newVal) => {
          if (newVal === page) return
          setPage(newVal)
          setLoading(true)
        }}
        totalPages={totalPages}
      />
    </HStack>
  )
}

export default MargemMercado
