import { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  eanOpt: IOption[]
}

const Ean: React.FC<IProps> = ({ row, eanOpt }) => {
  const [value, setValue] = useState(row.rival_category)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics/update'
      const req = {
        id: row.id,
        rival_category: newVal,
      }

      api.post(url, req).catch(() => {
        setValue(row.rival_category)
      })
    },
    [row]
  )

  return (
    <Select w="20rem" value={value} onChange={onChange}>
      {eanOpt?.map?.((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default Ean
