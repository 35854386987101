import styled from 'styled-components'
import { Table as MUITable } from '@material-ui/core'

export const Table = styled(MUITable)`
  margin-bottom: 2rem;

  thead tr {
    background-color: #f2f3f5;
  }

  tbody {
    tr,
    td {
      transition: background-color 0.3s;
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem 1rem;

    color: #003b74;
    font-size: 1rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :first-child {
      width: 200px;
    }

    :nth-child(2) {
      width: 300px;
    }
  }

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    font-size: 1rem;
  }
`

export const Error = styled.span`
  display: flex;
  align-items: center;

  color: red;

  svg {
    font-size: 18px;
    margin-right: 1ch;
  }
`

export const Add = styled.div`
  cursor: pointer;

  height: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: green;
  font-size: 3.2rem;
  border-radius: 4px;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #f2fff5;
  }
`

export const Options = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  svg {
    cursor: pointer;
    font-size: 2rem;

    :hover {
      filter: brightness(110%);
    }

    :first-child {
      color: red;
    }

    :last-child {
      color: green;
    }
  }
`

export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    cursor: pointer;

    color: red;
    font-size: 1.5rem;

    :hover {
      filter: brightness(110%);
    }
  }
`
