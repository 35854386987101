import { useCallback, useEffect, useState } from 'react'

import api from 'services/api'
import Paper from 'components/Paper'
import Autocomplete from 'components/Autocomplete'

import { columns } from './constants'
import { Box, Flex, HStack } from '@chakra-ui/react'

interface IProps {
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  setClusterId: React.Dispatch<React.SetStateAction<string>>
}

const Header: React.FC<IProps> = ({ setRows, setClusterId }) => {
  const [cluster, setCluster] = useState({ label: '', value: '' })
  const [clusterOpt, setClusterOpt] = useState<any>([])

  useEffect(() => {
    setClusterId(cluster.value)
  }, [cluster, setClusterId])

  const getSku = useCallback(() => {
    api.get('/clusters').then((res) => {
      setClusterOpt(
        res.data.data.clusters.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const getInfo = useCallback(() => {
    setRows([])

    if (!cluster.value) return

    api.get(`/relativity-channels/${cluster.value}`).then((res) => {
      setRows(res.data.data.channels)
    })
  }, [cluster, setRows])
  useEffect(getInfo, [getInfo])

  return (
    <Paper
      containerStyle={{
        padding: '2rem',
        marginBottom: '2rem',
        overflow: 'visible',
      }}
    >
      <HStack
        spacing={4}
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#f2f3f5"
        borderRadius="4px"
      >
        {columns.map((column) => (
          <Box w="14rem" key={column.Header}>
            {column.Header}
          </Box>
        ))}
      </HStack>
      <Flex justifyContent="space-between" pl="1rem">
        <Box w="14rem">
          <Autocomplete
            value={cluster}
            options={clusterOpt}
            setValue={setCluster}
          />
        </Box>
        {/* <Box w="14rem">
          <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
        </Box> */}
      </Flex>
    </Paper>
  )
}

export default Header
