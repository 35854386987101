import { useCallback, useRef } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  url: string
}

const ExportExcelButton: React.FC<IProps> = ({ url }) => {
  const toast = useToast()

  const linkRef = useRef<any>(null)
  const loadingRef = useRef<any>(null)

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise)
  }, [])

  const onClick = useCallback(() => {
    openLoading(
      'Gerando Excel...',
      api
        .post(url)
        .then((res) => {
          let url = res.data.data.url

          if (!url.match(/^https?:\/\//i)) {
            url = 'https://' + url
          }

          linkRef!.current!.href = url
          linkRef!.current!.click()
        })
        .catch(() => {
          toast({
            title: 'Erro, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        })
    )
  }, [openLoading, toast, url])

  return (
    <>
      <Button onClick={onClick}>Exportar Excel</Button>
      <a ref={linkRef} style={{ display: 'none' }} download />
      <LoadingModal ref={loadingRef} />
    </>
  )
}

export default ExportExcelButton
