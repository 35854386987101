import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'

import Table from './Table'
import Filters from './Filters'
import IRow from './types/IRow'
import IFilters from './types/IFilters'

const limit = 15

const ClassificacaoDoItem = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState<IOption[]>([])
  const [filters, setFilters] = useState({} as IFilters)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const getRows = useCallback(() => {
    const req: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      req[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api
      .post(`/sensitivity?page=${page}&limit=${limit}`, req)
      .then((response) => {
        setRows(response.data.data.sensitivities)
        setTotal(response.data.data.total)
        setTotalPage(response.data.data.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [filters, page, toast])
  useEffect(getRows, [getRows])

  const getOptions = useCallback(() => {
    api
      .get('/sensitivity/options')
      .then((response) => {
        setOptions(
          Object.keys(response.data.data.options).map((key: string) => ({
            label: response.data.data.options[key],
            value: response.data.data.options[key],
          }))
        )
      })
      .catch(() => {
        if (!toast.isActive('options')) {
          toast({
            id: 'options',
            title: 'Erro ao carregar opções, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [toast])
  useEffect(getOptions, [getOptions])

  return (
    <Paper>
      <Filters
        setPage={setPage}
        setLoading={setLoading}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        rows={rows}
        loading={loading}
        options={options}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newPage) => {
          setPage(newPage)
          setLoading(true)
        }}
      />
    </Paper>
  )
}

export default ClassificacaoDoItem
