import { useState, useEffect, useMemo, useCallback, Fragment } from 'react'
import { ListItemText } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { BiTrendingUp as OptimizerSvg } from 'react-icons/bi'
import { HiAdjustments as ParametersSvg } from 'react-icons/hi'
import { IoPricetagsSharp as PriceBaseSvg } from 'react-icons/io5'
import {
  FaHome as DashboardSvg,
  FaUsers as UsersSvg,
  FaCheckDouble as ApprovalSvg,
} from 'react-icons/fa'

import { IPage } from './interfaces'
import { Container, TopCurve, BottomCurve, Item, ListItemIcon } from './styles'

const ListItems: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem('@Prixsia:user') || '{}')
  }, [])

  const showItem = useCallback(
    (permission: string) => {
      return user?.roles?.find((role: any) =>
        ['ADMIN', permission].includes(role.name)
      )
    },
    [user]
  )

  const defaultPages = useMemo(
    () => [
      {
        active: false,
        showItem: true,
        title: 'Início',
        icon: <DashboardSvg />,
        navigatePath: '/dashboard',
      },
      {
        active: false,
        showItem: showItem('ADMIN'),
        title: 'Controle de Acessos',
        icon: <UsersSvg />,
        navigatePath: '/users',
      },
      {
        active: false,
        showItem: showItem('READ_PARAMETERS'),
        title: 'Parâmetros',
        icon: <ParametersSvg />,
        navigatePath: '/parameters',
      },
      {
        active: false,
        showItem: showItem('READ_SCENERY') || showItem('READ_DISCOUNT_SCENERY'),
        title: 'Gestão de Preços',
        icon: <PriceBaseSvg />,
        navigatePath: '/pricebase',
      },
      {
        active: false,
        showItem: true,
        title: 'Requisições',
        icon: <ApprovalSvg />,
        navigatePath: '/approval',
      },
      {
        active: false,
        showItem: true,
        title: 'Otimizador de Preços',
        icon: <OptimizerSvg size={30} />,
        navigatePath: '/pricesoptimizer',
      },
    ],
    [showItem]
  )

  const [pages, setPages] = useState<IPage[]>(defaultPages)

  const updateActivePage = useCallback(() => {
    const [, routeName] = pathname.split('/')
    setPages((prevState) => {
      return prevState.map((navItem) => ({
        ...navItem,
        active: navItem.navigatePath.includes(routeName),
      }))
    })
  }, [pathname])
  useEffect(updateActivePage, [updateActivePage])

  const handleNavigation = useCallback(
    (page: IPage) => {
      navigate(page.navigatePath)
    },
    [navigate]
  )

  return (
    <Container>
      {pages.map(
        (page) =>
          page.showItem && (
            <Fragment key={page.navigatePath}>
              <Item
                key={page.title}
                button
                active={String(page.active)}
                onClick={() => handleNavigation(page)}
              >
                <TopCurve className="curve" active={String(page.active)} />
                <BottomCurve className="curve" active={String(page.active)} />
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.title} />
              </Item>
            </Fragment>
          )
      )}
    </Container>
  )
}

export default ListItems
