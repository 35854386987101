import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'

import Table from './Table'
import IRow from './types/IRow'
import IPricePointOpt from './types/IPricePointOpt'

const limit = 15

const defaultOption = {
  label: 'Selecione...',
  value: '',
}

const EstrategiaDePosicionamento = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)
  const [channels, setChannels] = useState<IOption[]>([])

  const [eanOpt, setEanOpt] = useState<IOption[]>([])
  const [priceTypeOpt, setPriceTypeOpt] = useState({} as IPricePointOpt)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const getEanOpt = useCallback(() => {
    api.get('/materials/descriptions').then((res) => {
      setEanOpt([
        defaultOption,
        ...res.data.data.materials.map((item: any) => ({
          label: item.nivel05,
          value: item.nivel05,
        })),
      ])
    })
  }, [])
  useEffect(getEanOpt, [getEanOpt])

  const getPriceTypeOptions = useCallback(() => {
    api.get('/politics/calculation-type/options').then((res) => {
      const pricepoint = res.data?.data?.pricepoint
        ? [
            defaultOption,
            ...Object.keys(res.data.data.pricepoint).map((key) => ({
              label: res.data.data.pricepoint[key],
              value: key,
            })),
          ]
        : []

      const not_pricepoint = [
        defaultOption,
        ...(res.data.data?.not_pricepoint?.map?.((item: string) => ({
          label: item,
          value: item,
        })) || []),
      ]

      setPriceTypeOpt({ pricepoint, not_pricepoint })
    })
  }, [])
  useEffect(getPriceTypeOptions, [getPriceTypeOptions])

  const getRows = useCallback(() => {
    api
      .get(`/politics?page=${page}&limit=${limit}`)
      .then((response) => {
        setRows(
          response.data.data.politics.map((row: IRow) => ({
            ...row,
            date_ini: moment(row.date_ini).zone(0).format('YYYY-MM-DD'),
            date_end: moment(row.date_end).zone(0).format('YYYY-MM-DD'),
          }))
        )
        setTotal(response.data.data.total)
        setTotalPage(response.data.data.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
          })
        }
      })
  }, [page, toast])
  useEffect(getRows, [getRows])

  const getChannels = useCallback(() => {
    api.get('/channels').then((res) => {
      setChannels([
        defaultOption,
        ...res.data.data.channels.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ])
    })
  }, [])
  useEffect(getChannels, [getChannels])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Table
        rows={rows}
        channels={channels}
        eanOpt={eanOpt}
        priceTypeOpt={priceTypeOpt}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newPage) => {
          setLoading(true)
          setPage(newPage)
        }}
      />
    </Paper>
  )
}

export default EstrategiaDePosicionamento
