import { useCallback, useEffect, useState } from 'react'
import { AiOutlinePlusCircle as AddSvg } from 'react-icons/ai'
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import {
  FaTrash as DeleteSvg,
  FaInfoCircle as ErrorSvg,
  FaPlusCircle as CreateSvg,
  FaTimesCircle as CancelSvg,
} from 'react-icons/fa'

import api from 'services/api'
import Paper from 'components/Paper'
import Autocomplete from 'components/Autocomplete'

import InputRelativity from './InputRelativity'
import { columns, addColumns } from './constants'
import { Add, Error, Table, Options, DeleteContainer } from './styles'
import { Box, HStack } from '@chakra-ui/react'

interface IProps {
  rows: any[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  captain: string
}

const Body: React.FC<IProps> = ({ rows, setRows, captain }) => {
  const [isAdding, setIsAdding] = useState(false)

  const [sku, setSku] = useState({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<any>([])

  const [error, setError] = useState('')

  useEffect(() => {
    setError('')
  }, [sku])

  const getSku = useCallback(() => {
    api.get('/materials').then((res) => {
      setSkuOpt(
        res.data.data.materials.map(
          (item: any) =>
            +item.sku && {
              label: `${+item.sku} - ${item.descricao_produto}`,
              value: +item.sku + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const onDelete = useCallback(
    (skuToDel: any) => {
      api.delete(`/materials/${skuToDel}/captain`).then(() => {
        setRows((current: any) => {
          return current.filter((row: any) => row.sku !== skuToDel)
        })
      })
    },
    [setRows]
  )

  const onCancel = useCallback(() => {
    setSku({ label: '', value: '' })
    setError('')
    setIsAdding(false)
  }, [])
  useEffect(onCancel, [onCancel, rows])

  const onAdd = useCallback(() => {
    const url = `/captains/${captain}/materials`
    const req = { sku: +sku.value, relativity: 100 }
    api
      .post(url, req)
      .then((res) => {
        onCancel()
        setRows((current) => [
          ...current,
          {
            sku: res.data.data.captain.sku,
            description: res.data.data.captain.description,
            relativity: res.data.data.captain.relativity,
          },
        ])
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }, [sku, captain, onCancel, setRows])

  if (rows?.length === 0) return null

  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                <InputRelativity row={row} captain={captain} />
              </TableCell>
              <TableCell>
                <DeleteContainer>
                  <DeleteSvg onClick={() => onDelete(row.sku)} />
                </DeleteContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isAdding ? (
        <>
          <HStack
            spacing={4}
            py="1rem"
            pl="1rem"
            mb="1rem"
            color="#003b74"
            bgColor="#f2f3f5"
            borderRadius="4px"
          >
            {addColumns.map((column) => (
              <Box w="14rem" key={column.Header}>
                {column.Header}
              </Box>
            ))}
          </HStack>
          <HStack spacing={4} pl="1rem">
            <Box w="35rem">
              <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
            </Box>
            <Box w="20rem">
              {error && (
                <Error>
                  <ErrorSvg /> {error}
                </Error>
              )}
            </Box>
            <Box w="100%">
              <Options>
                <CancelSvg onClick={onCancel} />
                <CreateSvg onClick={onAdd} />
              </Options>
            </Box>
          </HStack>
        </>
      ) : (
        <Add onClick={() => setIsAdding(true)}>
          <AddSvg />
        </Add>
      )}
    </Paper>
  )
}

export default Body
