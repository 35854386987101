import { Flex, Grid, Text } from '@chakra-ui/react'
import { useMemo, useState } from 'react'

const MOCK_DATA = [
  {
    title: 'Faturamento do Ano',
    sections: [
      { title: 'Meta', value: '6.280.000' },
      { title: 'Realizados', value: '3.580.258', color: 'green' },
      { title: 'Restante', value: '2.699.742' },
    ],
  },
  {
    title: 'Faturamento do Mês Atual',
    sections: [
      { title: 'Meta', value: '210.000' },
      { title: 'Realizados', value: '81.255', color: 'green' },
      { title: 'Restante', value: '128.745' },
    ],
  },
  {
    title: 'Descontos On in Voice',
    sections: [
      { title: 'Orçamento', value: '1.200.000' },
      { title: 'Utilizado', value: '159.255', color: 'green' },
      { title: 'Restante', value: '1.040.745' },
    ],
  },
  {
    title: 'Descontos Off in Voice',
    sections: [
      { title: 'Orçamento', value: '2.200.000' },
      { title: 'Utilizado', value: '1.859.581', color: 'yellow' },
      { title: 'Restante', value: '659.581' },
    ],
  },
  {
    title: 'Faturamento ano por Canal',
    sections: [
      { title: 'Varejo', value: '1.659.944' },
      { title: 'Atacado', value: '841.541' },
      { title: 'KAN Varejo', value: '632.547' },
      { title: 'Distribuidor', value: '446.226' },
    ],
  },
  {
    title: 'Faturamento mês por Canal',
    sections: [
      { title: 'Varejo', value: '50.944' },
      { title: 'Atacado', value: '21.541' },
      { title: 'KAN Varejo', value: '5.223' },
      { title: 'Distribuidor', value: '3.547' },
    ],
  },
  {
    title: 'Descontos On in voice por Canal',
    sections: [
      { title: 'Varejo', value: '59.944' },
      { title: 'Atacado', value: '41.541' },
      { title: 'KAN Varejo', value: '32.547' },
      { title: 'Distribuidor', value: '25.223' },
    ],
  },
  {
    title: 'Descontos Off in voice por Canal',
    sections: [
      { title: 'Varejo', value: '659.944' },
      { title: 'Atacado', value: '441.541' },
      { title: 'KAN Varejo', value: '425.549' },
      { title: 'Distribuidor', value: '332.547' },
    ],
  },
]

const colors = [
  '#63B3ED',
  '#4299E1',
  '#3182CE',
  '#2B6CB0',
  '#63B3ED',
  '#4299E1',
  '#3182CE',
  '#2B6CB0',
]

const Cards: React.FC = () => {
  const [data, setData] = useState(MOCK_DATA)

  const isDemo = useMemo(() => {
    return (
      JSON.parse(localStorage['@Prixsia:user']).email ===
        'prixsia.demo.ind@pricemet.com.br' ||
      JSON.parse(localStorage['@Prixsia:user']).email ===
        'prixsia.demo.ind.hml@pricemet.com.br'
    )
  }, [])

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gridGap="1rem"
      overflow="hidden"
      filter={isDemo ? 'none' : 'grayscale(.8) opacity(.5)'}
    >
      {data.map((card, index) => (
        <Flex
          key={card.title}
          p="1rem"
          flexDir="column"
          bgColor="white"
          position="relative"
        >
          <Flex
            pos="absolute"
            top="0"
            right="0"
            w="0"
            h="0"
            borderStyle="solid"
            borderWidth="0 30px 30px 0"
            borderColor={`transparent ${colors[index]} transparent transparent`}
          />
          <Text
            mb="0.75rem"
            fontWeight="semibold"
            fontSize="md"
            color="gray.700"
          >
            {card.title}
          </Text>
          {card.sections.map((section) => (
            <Flex
              key={section.title}
              justify="space-between"
              fontSize="sm"
              py="0.125rem"
            >
              <Text fontWeight="normal" color="gray.700">
                {section.title}
              </Text>
              <Text
                fontWeight="semibold"
                color={section.color ? `${section.color}.400` : '#003b74'}
              >
                R$ {section.value}
              </Text>
            </Flex>
          ))}
        </Flex>
      ))}
    </Grid>
  )
}

export default Cards
