import { useCallback, useEffect, useMemo, useState } from 'react'
import { HStack, useToast } from '@chakra-ui/react'

import api from 'services/api'

import IRow from './types/IRow'
import Table from './Table'
const limit = 15
const binaryPos = [1, 2, 4, 8, 16]

const RegrasDeDesconto = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)
  const [request, setRequest] = useState({})

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [request])
  const percConvert = useCallback((v: string, dec: number) => {
    return (
      String((Number(v.replace(',', '.')) * 100).toFixed(dec)).replace(
        '.',
        ','
      ) + '%'
    )
  }, [])
  const getRows = useCallback(() => {
    if (!loading) return
    const url = `/fator-icms/list?page=${page}&limit=${limit}`
    api
      .post(url)
      .then(({ data }) => {
        setRows(
          data.data.fator.map((item: any) => ({
            icms_destino: percConvert(item.icms_destino, 1),
            icms: percConvert(item.icms, 1),
            mva: percConvert(item.mva, 2),
            fator: item.fator.replace(',', '.'),
          }))
        )
        setTotal(data.data.total)
        setTotalPage(data.data.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [page, toast, loading, percConvert])
  useEffect(getRows, [getRows])

  return (
    <HStack align="flex-start" spacing={6}>
      <Table
        rows={rows}
        loading={loading}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={total}
        setPage={(newVal) => {
          if (newVal === page) return
          setPage(newVal)
          setLoading(true)
        }}
        totalPages={totalPages}
      />
    </HStack>
  )
}

export default RegrasDeDesconto
