import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from 'pages/Login'
import AppEntry from 'pages/AppEntry'
import AuthDemo from 'pages/AuthDemo'

import Dashboard from 'pages/Dashboard'

import Users from 'pages/Users'
import UsersEdit from 'pages/UsersEdit'

import PriceBase from 'pages/PriceBase'
import PriceBaseCreate from 'pages/PriceBaseCreate'
import PriceBaseTable from 'pages/PriceBaseTable'
import PriceBaseDiscountV2 from 'pages/PriceBaseDiscountV2'

import PricesOptimizer from 'pages/PricesOptimizer'

import Approval from 'pages/Approval'
import ApprovalPriceBaseTableV2 from 'pages/ApprovalPriceBaseTableV2'
import ApprovalPriceBaseDiscountV2 from 'pages/ApprovalPriceBaseDiscountV2'

import Parameters from 'pages/Parameters'
import ParametersEdit from 'pages/ParametersEdit'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

const RoutesProvider: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index element={<AppEntry />} />
          <Route path="*" element={<AppEntry />} />
          <Route path="login" element={<Login />} />
          <Route path="authentication-demo" element={<AuthDemo />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="users/edit/:userId" element={<UsersEdit />} />
          <Route path="pricebase" element={<PriceBase />} />
          <Route path="pricebase/create" element={<PriceBaseCreate />} />
          <Route
            path="pricebase/table/:id/:type"
            element={<PriceBaseTable />}
          />
          <Route
            path="pricebase/discount/:id/:type"
            element={<PriceBaseDiscountV2 />}
          />
          <Route path="approval" element={<Approval />} />
          <Route
            path="approval/table/:id/:type"
            element={<ApprovalPriceBaseTableV2 />}
          />
          <Route
            path="approval/discount/:id/:type"
            element={<ApprovalPriceBaseDiscountV2 />}
          />
          <Route path="parameters" element={<Parameters />} />
          <Route path="parameters/edit/:b64data" element={<ParametersEdit />} />
          <Route path="pricesoptimizer" element={<PricesOptimizer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesProvider
