import { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  options: IOption[]
}

const Input: React.FC<IProps> = ({ row, options }) => {
  const [deflator, setDeflator] = useState(row.sensitivity)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setDeflator(newVal)

      const url = '/sensitivity'
      const req = {
        cluster_id: row.cluster_id,
        captain_sku: row.captain_sku,
        sensitivity: newVal,
      }

      api.put(url, req).catch(() => {
        setDeflator(row.sensitivity)
      })
    },
    [row]
  )

  return (
    <Select value={deflator} onChange={onChange}>
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default Input
