import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import api from 'services/api'

const useEdit = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { userId } = useParams()

  const [name, setName] = useState('')
  const [roles, setRoles] = useState<{ id: number; name: string }[]>([])
  const [nivel, setNivel] = useState(1)
  const [email, setEmail] = useState('')
  const [access, setAccess] = useState(false)
  const [active, setActive] = useState(false)
  const [enrollment, setEnrollment] = useState('')
  const [defaultInfo, setDefaultInfo] = useState({
    name: '',
    email: '',
    enrollment: '',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setRoles((current) => {
      if (access) {
        return [...current, { id: 1, name: 'ADMIN' }]
      }

      return current.filter((role) => role.name !== 'ADMIN')
    })
  }, [access])

  const getUser = useCallback(() => {
    api.get(`/users/${userId}`).then((response) => {
      const { user } = response.data.data

      setName(user.name)
      setEmail(user.email)
      setNivel(+user.approval_levels?.at(-1)?.title?.split('0')[1] || 1)
      setRoles(user.roles || [])
      setActive(user.active)
      setEnrollment(user.enrollment)
      setDefaultInfo({
        name: user.name || '',
        email: user.email || '',
        enrollment: user.enrollment || '',
      })

      if (user.roles?.find((role: any) => role.name === 'ADMIN')) {
        setAccess(true)
      }

      setLoading(false)
    })
  }, [userId])
  useEffect(getUser, [getUser])

  const onCancel = useCallback(() => {
    navigate('/users')
  }, [navigate])

  const onSave = useCallback(() => {
    const url = `/users/${userId}`
    const request = {
      name,
      email,
      roles,
      nivel: +nivel,
      access,
      enrollment,
      active: +active,
    }

    return api
      .put(url, request)
      .then(() => {
        navigate('/users')
        toast({
          title: 'Usuário editado com sucesso!',
          status: 'success',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
        })
      })
  }, [
    name,
    email,
    enrollment,
    roles,
    nivel,
    access,
    active,
    userId,
    toast,
    navigate,
  ])

  return {
    onSave,
    onCancel,
    name,
    email,
    nivel,
    roles,
    access,
    active,
    enrollment,
    setName,
    setEmail,
    setNivel,
    setRoles,
    setAccess,
    setActive,
    setEnrollment,
    defaultInfo,
    loading,
  }
}

export default useEdit
