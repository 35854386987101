import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from 'services/api'
import Paper from 'components/Paper'
import TableBig from 'components/TableBig'
import LoadingScreen from 'components/LoadingScreen'

import Status from './Status'
import { EditSvg } from './styles'

const rolesDictionary = (name: string) => {
  return (
    {
      ADMIN: 'Admin',
      READ_PARAMETERS: 'Visualizar Parâmetros',
      WRITE_PARAMETERS: 'Editar Parâmetros',
      READ_SCENERY: 'Visualizar Simulador Preço Tabela',
      WRITE_SCENERY: 'Editar Simulador Preço Tabela',
      READ_DISCOUNT_SCENERY: 'Visualizar Simulador Preço Desconto',
      WRITE_DISCOUNT_SCENERY: 'Editar Simulador Preço Desconto',
    }[name] || name
  )
}

const handleRoles = (roles: any) => {
  if (!Array.isArray(roles)) return ''
  if (roles.find((role) => /admin/i.test(role.name))) return 'Admin'

  return (
    roles
      .map((r: any) => rolesDictionary(r.name))
      .sort((a: any, b: any) => a.localeCompare(b))
      .join('; ') || ''
  )
}

const Table: React.FC = () => {
  const navigate = useNavigate()

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)

  const editUser = useCallback(
    (id: string | number) => {
      navigate(`/users/edit/${id}`)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Acessos', accessor: 'roles' },
      { Header: 'Área de Atuação', accessor: 'regions' },
      {
        Header: 'Ativo',
        accessor: 'active',
        Cell: ({ row }: any) => <Status active={row.original.active} />,
      },
      {
        Header: 'Editar',
        accessor: 'edit',
        Cell: ({ row }: any) => (
          <EditSvg onClick={() => editUser(row.original.id)} />
        ),
      },
    ]
  }, [editUser])

  const getUsers = useCallback(() => {
    api.get('/users/all').then((response) => {
      setRows(
        response.data.data.users.map((user: any) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          active: user.active,
          roles: handleRoles(user.roles),
          regions: user.regions?.map((r: any) => r.name).join('; ') || '',
          edit: '',
        }))
      )
      setLoading(false)
    })
  }, [])

  useEffect(getUsers, [getUsers])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBig data={rows} columns={columns} />
    </Paper>
  )
}

export default Table
