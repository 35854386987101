import { useCallback, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  param: string
  updateRow: (newRow: { [key: string]: any }) => void
}

const Input: React.FC<IProps> = ({ row, param, updateRow }) => {
  const [value, setValue] = useState(row[param] || 0)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    console.log(+e.target.value, e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    console.log({ row, col: row[param], value })
    const req: { [key: string]: any } = {
      icms: row.icms,
      icms_destino: row.icms_destino,
      mva: row.mva,
      [param]: value,
    }
    console.log(req)
    const url = '/fator-icms/update'
    api
      .put(url, req)
      .then((res) => {
        // updateRow({ ...row, ...res.data.data.fator })
      })
      .catch(() => {
        setValue(row[param])
      })
  }, [param, row, value])
  return (
    <InputGroup w="10rem">
      {/* <InputLeftAddon>%</InputLeftAddon> */}
      <ChakraInput
        type="number"
        defaultValue={value}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </InputGroup>
  )
}

export default Input
