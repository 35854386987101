import { useCallback, useEffect, useState } from 'react'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'

import Autocomplete from 'components/Autocomplete'

import { MOCK_CLUSTER_OPT, MOCK_PRODUCT_OPT } from '../MOCK_DATA'
import { FaFilter } from 'react-icons/fa'

interface IProps {
  setProductId: React.Dispatch<React.SetStateAction<string>>
}

const Filters: React.FC<IProps> = ({ setProductId }) => {
  const [channel, setChannel] = useState<IOption>({} as IOption)
  const [cluster, setCluster] = useState<IOption>({} as IOption)
  const [product, setProduct] = useState<IOption>({} as IOption)
  const [channelOpt, setChannelOpt] = useState<IOption[]>([
    { label: 'Atacado', value: '1' },
    { label: 'Varejo', value: '2' },
    { label: 'Distribuidor', value: '3' },
  ])
  const [clusterOpt, setClusterOpt] = useState<IOption[]>([])
  const [productOpt, setProductOpt] = useState<IOption[]>([])

  useEffect(() => {
    setProductId(product.value)
  }, [product, setProductId])

  const getClusterOpt = useCallback(() => {
    setClusterOpt(
      MOCK_CLUSTER_OPT.data.clusters.map((cluster) => ({
        label: cluster.name,
        value: cluster.id + '',
      }))
    )
  }, [])
  useEffect(getClusterOpt, [getClusterOpt])

  const getProductOpt = useCallback(() => {
    setProduct({} as IOption)
    setProductOpt([])

    if (!cluster.value) return

    console.log(
      'MOCK_PRODUCT_OPT.data.materials',
      MOCK_PRODUCT_OPT.data.materials
    )

    setProductOpt(
      MOCK_PRODUCT_OPT.data.materials
        .filter((material) => material.cluster_id === cluster.value)
        .map((material) => ({ label: material.name, value: material.id + '' }))
    )
  }, [cluster.value])
  useEffect(getProductOpt, [getProductOpt])

  return (
    <Flex
      w="100%"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bgColor="#fff"
      overflow="visible"
    >
      <Flex
        align="center"
        fontWeight="semibold"
        fontSize="sm"
        p="1rem"
        pb="0"
        borderTopRadius="lg"
        color="#003b74"
      >
        <Text mr="1rem">
          <FaFilter />
        </Text>
        <Text fontSize="md">Selecione Para Carregar os Dados</Text>
      </Flex>
      <VStack w="100%" p="1rem" align="flex-start">
        <HStack w="100%" spacing="4rem">
          <HStack w="100%">
            <Text w="6.75rem" fontSize="sm">
              Cluster
            </Text>
            <Autocomplete
              value={cluster}
              options={clusterOpt}
              setValue={setCluster}
            />
          </HStack>
          <HStack w="100%">
            <Text w="6rem" fontSize="sm">
              Canal
            </Text>
            <Autocomplete
              value={channel}
              options={channelOpt}
              setValue={setChannel}
            />
          </HStack>
        </HStack>
        <HStack w="100%">
          <Text w="6rem" fontSize="sm">
            Produto
          </Text>
          <Autocomplete
            value={product}
            options={productOpt}
            setValue={setProduct}
          />
        </HStack>
      </VStack>
    </Flex>
  )
}

export default Filters
