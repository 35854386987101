import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from 'services/api'
import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'

import IFilters from '../types/IFilters'

import SubRow from './SubRow'
import { formatData } from './actions'
import { colsSimple, colsDetailed } from './constants'

const limit = 15

interface IProps {
  filters: IFilters
  simpleView: boolean
  toggleBigData: () => void
}

const Table: React.FC<IProps> = ({ filters, simpleView, toggleBigData }) => {
  const { id, type } = useParams()

  const [rows, setRows] = useState<Record<string, unknown>[]>([])
  const [loading, setLoading] = useState(true)
  const [allChecked, setAllChecked] = useState(false)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  useEffect(() => {
    setAllChecked(false)
  }, [filters])

  const cols = useMemo(() => {
    return simpleView ? colsSimple : colsDetailed
  }, [simpleView])

  const inputsRef = useRef<any[]>([])

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, rows.length)
  }, [rows.length])

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [type, filters, simpleView])

  const getRows = useCallback(() => {
    const url = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`
    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api.post(url, request).then((res) => {
      setRows(formatData(res.data.data.scenery_items))
      setTotal(res.data.data.total)
      setTotalPage(res.data.data.totalPages)
      setLoading(false)
    })
  }, [filters, id, page, type])
  useEffect(() => {
    if (loading) getRows()
  }, [getRows, loading])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
        cellProps={{
          filters,
          getRows,
          inputsRef,
          allChecked,
          setAllChecked,
          toggleBigData,
        }}
        stickyPos={simpleView ? 8 : 8}
        subRowComponent={SubRow}
      />
    </Paper>
  )
}

export default memo(Table)
