import { useMemo } from 'react'

import MargemMercado from './MargemMercado'
import Arredondamento from './Arredondamento'
import ClusterDePreco from './ClusterDePreco'
import UploadDePrecos from './UploadDePrecos'
import MargemIndustria from './MargemIndustria'
import RegrasDeDesconto from './RegrasDeDesconto'
import RelatividadeCanais from './RelatividadeCanais'
import ClassificacaoDoItem from './ClassificacaoDoItem'
import RelatividadeDeProduto from './RelatividadeDeProduto'
import EstrategiaDePosicionamento from './EstrategiaDePosicionamento'
import FatorICMS from './FatorICMS'
interface IProps {
  param: string
  inputs: Record<string, unknown>
}

const Table: React.FC<IProps> = ({ param, inputs }) => {
  return useMemo(() => {
    return (
      {
        Arredondamento: <Arredondamento />,
        'Margem Mercado': <MargemMercado />,
        'Margem Indústria': <MargemIndustria />,
        'Cluster de Preço': <ClusterDePreco />,
        'Upload de Preços': <UploadDePrecos />,
        'Regras de Desconto': <RegrasDeDesconto />,
        'Classificação do Item': <ClassificacaoDoItem />,
        'Relatividade de Canais': <RelatividadeCanais />,
        'Relatividade de Produto': <RelatividadeDeProduto />,
        'Estratégia de Posicionamento': <EstrategiaDePosicionamento />,
        'Fator ICMS': <FatorICMS />,
      }[param] || null
    )
  }, [param])
}

export default Table
