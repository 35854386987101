import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'

import TUseAdd from './types'

const useAdd: TUseAdd = ({ isOpen }) => {
  const toast = useToast()

  const [value, setValue] = useState<IOption>({ label: '', value: '' })
  const [valid, setValid] = useState(false)
  const [error, setError] = useState('')
  const [options, setOptions] = useState<IOption[]>([])

  const resetStatesOnCloseModal = useCallback(() => {
    if (!isOpen) {
      setValue({ label: '', value: '' })
      setValid(false)
      setError('')
      setOptions([])
    }
  }, [isOpen])
  useEffect(resetStatesOnCloseModal, [resetStatesOnCloseModal])

  const getInputOptions = useCallback(() => {
    if (!isOpen) return
    api.get('/materials/non-captain').then((res) => {
      setOptions(
        res.data.data.materials.map(
          (item: any) =>
            +item.sku && {
              label: `${+item.sku} - ${item.descricao_produto}`,
              value: +item.sku + '',
            }
        )
      )
    })
  }, [isOpen])
  useEffect(getInputOptions, [getInputOptions])

  const validateIfIsCaptain = useCallback(() => {
    if (!value.value) return
    api
      .get(`/captains/${value.value}/validate`)
      .then(() => {
        setValid(true)
        setError('')
      })
      .catch((e) => {
        setValid(false)
        setError(e.response.data.message)
      })
  }, [value.value])
  useEffect(validateIfIsCaptain, [validateIfIsCaptain])

  const onAdd = useCallback(() => {
    const url = '/captains'
    const req = { sku: +value.value }

    api
      .post(url, req)
      .then(() => {
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
        })
        setTimeout(() => window.location.reload(), 1000)
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
      })
  }, [toast, value.value])

  return {
    error,
    valid,
    value,
    onAdd,
    options,
    setValue,
  }
}

export default useAdd
