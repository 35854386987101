import { Text, VStack } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete'

const Category: React.FC = () => {
  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Categoria de Produtos
      </Text>
      <Autocomplete options={[]} value={[]} setValue={() => {}} isMulti />
    </VStack>
  )
}

export default Category
