import { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'
import { money } from 'utils/masks'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
}

const Indice: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState(row.index)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(money(e.target.value))
  }, [])

  const onBlur = useCallback(() => {
    const url = '/politics/update'
    const req = {
      id: row.id,
      index: value,
    }

    api.post(url, req).catch(() => {
      setValue(row.index)
    })
  }, [row, value])

  return (
    <InputGroup w="8rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        minW="6rem"
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </InputGroup>
  )
}

export default Indice
