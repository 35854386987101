import { useCallback, useState } from 'react'
import { Divider, useToast, Flex, HStack, Input } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'

import Bundles from './Bundles'
import { Container } from './styles'

interface IRow {
  id: number | string
  name: string
  factory: string
}

interface IProps {
  row: IRow
  isExpanded: boolean
}

const Panel: React.FC<IProps> = ({ row, isExpanded }) => {
  const toast = useToast()

  const [name, setName] = useState(row.name)
  const [factory, setFactory] = useState(row.factory)

  const onSave = useCallback(() => {
    const request = { id: row.id, name, factory }

    api
      .put('/clusters', request)
      .then(() => {
        toast({
          title: 'Salvo com sucesso!',
          status: 'success',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [factory, name, row.id, toast])

  return (
    <Container>
      <Flex justifyContent="space-between">
        <HStack>
          <Input
            placeholder="Fábrica"
            value={factory}
            onChange={(e) => setFactory(e.target.value)}
          />
          <Input
            placeholder="Nome do Cluster"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </HStack>
        <Button onClick={onSave}>Salvar</Button>
      </Flex>
      <Divider my={8} />
      <Bundles row={row} isExpanded={isExpanded} />
    </Container>
  )
}

export default Panel
