import { useCallback, useEffect, useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Input,
  Radio,
  RadioGroup,
  Divider,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

import { UploadButton } from './styles'
import { idpCols, idpRows, pricepointCols, pricePointRows } from './constants'

const PrecosDeMercadoUpload: React.FC = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loadingRef = useRef<any>(null)
  const [name, setName] = useState('')
  const [type, setType] = useState('PESQUISA')

  const handleClose = useCallback(() => {
    if (!isOpen) {
      setName('')
      setType('PESQUISA')
    }
  }, [isOpen])
  useEffect(handleClose, [handleClose])

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const handleUpload = useCallback(
    (formData: FormData) => {
      openLoading(
        'Fazendo upload...',
        api
          .post(
            `/market-prices/upload?upload_type=${type}&name=${name}`,
            formData
          )
          .then(() => {
            toast({
              title: 'Upload realizado com sucesso.',
              status: 'success',
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch((error) => {
            toast({
              title: `${error.response.status} - Erro ao fazer upload, verifique o arquivo e tente novamente.`,
              status: 'error',
            })
          })
      )
    },
    [name, openLoading, toast, type]
  )

  const onUpload = useCallback(
    (e: any) => {
      if (name.length < 3) {
        return toast({
          title: 'Campo nome é obrigatório.',
          status: 'error',
        })
      }

      const fileName = e.target?.files?.[0]?.name

      if (!fileName || !/\.xlsx$/i.test(fileName)) {
        return toast({
          title: 'Arquivo inválido. Por favor envie no formato excel (.xlsx).',
          status: 'error',
        })
      }

      const file = e.target.files[0]

      if (file.size >= 4e7) {
        return toast({
          title: 'Arquivo inválido. Limite 40mb.',
          status: 'error',
        })
      }

      const formData = new FormData()

      formData.append('file', file)

      handleUpload(formData)
    },
    [handleUpload, name.length, toast]
  )

  const downloadExample = useCallback(() => {
    const excelRows = type === 'PESQUISA' ? idpRows : pricePointRows
    const excelCols = type === 'PESQUISA' ? idpCols : pricepointCols

    const wb = XLSX.utils.book_new()
    const ws1 = XLSX.utils.json_to_sheet(excelRows, {
      header: excelCols,
    })

    XLSX.utils.book_append_sheet(wb, ws1)
    XLSX.writeFile(wb, `modelo.xlsx`)
  }, [type])

  return (
    <>
      <Button onClick={onOpen}>Upload de Excel</Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30rem">
          <ModalHeader>Upload de Excel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={2} align="flex-start">
              <VStack w="100%" mb="1rem" align="flex-start">
                <Text>Nome</Text>
                <Input
                  maxLength={32}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </VStack>
              <Text>Selecione o tipo de preço</Text>
              <RadioGroup onChange={setType} value={type}>
                <VStack align="flex-start">
                  <Radio value="PESQUISA">Pesquisa</Radio>
                  <Radio value="PRICEPOINT">PricePoint</Radio>
                </VStack>
              </RadioGroup>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%">
              <Flex w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <label htmlFor="upload">
                  <input
                    type="file"
                    id="upload"
                    name="upload"
                    onChange={onUpload}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement
                      element.value = ''
                    }}
                    style={{ display: 'none' }}
                  />
                  <UploadButton>Carregar Arquivo</UploadButton>
                  <LoadingModal ref={loadingRef} />
                </label>
              </Flex>
              <Divider pt="0.5rem" />
              <Button
                width="100%"
                onClick={downloadExample}
                containerStyle={{
                  marginTop: '1rem',
                  backgroundColor: '#48BB78',
                }}
              >
                Download Planilha Modelo
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PrecosDeMercadoUpload
