import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'

import Table from './Table'
import IRow from './types/IRow'

const limit = 20

const UploadDePrecos = () => {
  const toast = useToast()

  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const getRows = useCallback(() => {
    api
      .get(`/market-prices/upload?page=${page}&limit=${limit}`)
      .then((response) => {
        setRows(response.data.data.uploads)
        setTotal(response.data.data.total)
        setTotalPage(response.data.data.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [page, toast])
  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Table
        rows={rows}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true)
          setPage(newPage)
        }}
        totalPages={totalPages}
      />
    </Paper>
  )
}

export default UploadDePrecos
