import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import api from 'services/api'
import Paper from 'components/Paper'
import Status from 'components/Status'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'

import { ViewSvg } from './styles'

const limit = 15

const Table: React.FC = () => {
  const navigate = useNavigate()

  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const onApprove = useCallback(
    (id: string, type: string, status: string) => {
      let url =
        type === 'DISCOUNT'
          ? `/approval/discount/${id}`
          : `/approval/table/${id}`

      if (/aguardando/i.test(status)) {
        url += '/edit'
      } else {
        url += '/view'
      }

      navigate(url)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Solicitante', accessor: 'user' },
      { Header: 'Nome do Cenário', accessor: 'name' },
      {
        Header: 'Tipo de Preço',
        accessor: ({ row }: any) => (
          <>{row.scenery.type === 'DISCOUNT' ? 'Desconto' : 'Tabela'}</>
        ),
      },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <>{moment(row.updated_at).zone(0).format('DD/MM/YYYY HH:mm')}</>
        ),
      },
      {
        Header: 'Status',
        accessor: ({ row }: any) => <Status status={row.status} />,
      },
      {
        Header: 'Aprovar',
        accessor: ({ row }: any) => (
          <ViewSvg
            onClick={() => onApprove(row.id, row.scenery.type, row.status)}
          />
        ),
      },
    ]
  }, [onApprove])

  const getUsers = useCallback(() => {
    api
      .get(`/requests/pending-approval?page=${page}&limit=${limit}`)
      .then((res) => {
        console.log(res)
        setRows(res.data.data.requests)
        setTotal(res.data.data.total)
        setTotalPage(res.data.data.totalPages)
        setLoading(false)
      })
  }, [page])

  useEffect(getUsers, [getUsers])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
      />
    </Paper>
  )
}

export default Table
