import { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, HStack } from '@chakra-ui/react'
import { FaInfoCircle as ErrorSvg } from 'react-icons/fa'

import api from 'services/api'
import Paper from 'components/Paper'
import Autocomplete from 'components/Autocomplete'

import Add from './Add'
import { Error } from './styles'
import { columns } from './constants'

interface IProps {
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  setCaptain: React.Dispatch<React.SetStateAction<string>>
}

const Header: React.FC<IProps> = ({ setRows, setCaptain }) => {
  const [sku, setSku] = useState({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<any>([])

  const [desc, setDesc] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setCaptain(sku.value)
  }, [sku, setCaptain])

  const getSku = useCallback(() => {
    api.get('/captains').then((res) => {
      setSkuOpt(
        res.data.data.captains.map(
          (item: any) =>
            +item.sku && {
              label: `${+item.sku} - ${item.description}`,
              value: +item.sku + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const getInfo = useCallback(() => {
    setRows([])
    setDesc('')
    setError('')

    if (!sku.value) return

    api
      .get(`/captains/${sku.value}/materials`)
      .then((res) => {
        setDesc(res.data.data.captain.description)
        setRows(res.data.data.materials)
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }, [sku, setRows])
  useEffect(getInfo, [getInfo])

  return (
    <Paper
      containerStyle={{
        padding: '2rem',
        marginBottom: '2rem',
        overflow: 'visible',
      }}
    >
      <HStack
        spacing={4}
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#f2f3f5"
        borderRadius="4px"
      >
        {columns.map((column) => (
          <Box w="30rem" key={uuid()}>
            {column.Header}
          </Box>
        ))}
      </HStack>
      <HStack spacing={4} pl="1rem">
        <Box w="30rem">
          <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
        </Box>
        <Box w="30rem">{desc}</Box>
        <Box w="30rem">
          <Add />
        </Box>
        <Box w="30rem">
          {error && (
            <Error>
              <ErrorSvg /> {error}
            </Error>
          )}
        </Box>
      </HStack>
    </Paper>
  )
}

export default Header
