import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import {
  FaArrowUp as PlusSvg,
  FaArrowDown as MinusSvg,
  FaEquals as EqualSvg,
} from 'react-icons/fa'

interface IProps {
  row: { [key: string]: any }
}

export const Indicator: React.FC<IProps> = ({ row }) => {
  const ppv = useMemo(() => {
    return +row.ppv_sugestao.replace(',', '.')
  }, [row.ppv_sugestao])

  const mrg = useMemo(() => {
    return +row.preco_liquido_unitario.replace(',', '.')
  }, [row.preco_liquido_unitario])

  const svg = useMemo(() => {
    if (ppv > mrg) return <PlusSvg />
    if (ppv < mrg) return <MinusSvg />
    return <EqualSvg />
  }, [mrg, ppv])

  const color = useMemo(() => {
    if (ppv > mrg) return 'green.500'
    if (ppv < mrg) return 'red.500'
    return 'yellow.500'
  }, [mrg, ppv])

  return (
    <Box pl="0.5rem" color={color} pos="absolute" left="-2rem">
      {svg}
    </Box>
  )
}

export default Indicator
